.dataLogger {
  position: absolute;
  margin-top: 171vh;
  padding-top: 450px;
  transform: scale(0.9);
}
.logger_img {
  margin-top: -3%;
  margin-left: -152px;
}
.ts_16_d {
  margin-top: 210px;
  position: relative;
  top: -805px;
  bottom: 0;
  left: 0%;
  z-index: 999;
}
.btn_r_dl {
  margin-top: -652px;
  margin-left: 876px;
}
.navi {
  padding-top: 434px;
  position: absolute;
  margin-left: -127px;
  width: 100%;
  z-index: 9999;
}
.nav4 {
  padding-top: 456px;
  margin-left: -119px;
  width: 100%;
}
.button_exp {
  z-index: 9999999;
}
.logger_text {
  color: whitesmoke;
  font-weight: 400;
  font-size: 80px;
  width: 100% !important;
}
.logger_col {
  padding-left: 57%;
  margin-top: -50%;

  width: 100% !important;
}
.logger_text2 {
  color: whitesmoke;
  font-size: 30px;
  font-weight: 500;
  right: -728%;
  position: relative;
  z-index: 1066;
  top: -183px;
}
@media (min-width: 1024px) and (max-width: 1200) {
  .navi {
    padding-top: 551px !important;
    margin-left: -27px !important;
  }
  .logger_col {
    padding-left: 51%;
    margin-top: -43%;
    z-index: 999;
    width: 100% !important;
  }
  .logger_text {
    font-size: 77px;
    width: 100% !important;
  }
  .logger_text2 {
    color: whitesmoke;
    font-size: 30px;
    font-weight: 500;
    right: -723%;
    position: relative;
    z-index: 1066;
    top: -46px;
  }
}

@media (min-width: 2500px) {
  .btn_r_dl {
    margin-top: -40rem;
    margin-left: 158rem;
    transform: scale(1.3);
  }
  .ion-row_d {
    background: black;
    height: 100vh;
  }
  .logger_img {
    margin-top: 0%;
    margin-left: -81px;
  }
  .navi {
    padding-top: 765px;
  }
  .logger_col {
    padding-left: 57%;
    margin-top: -26%;
    z-index: 999;
    width: 100% !important;
    transform: scale(1.1);
  }
  .lef3 {
    margin-top: 210px !important;
    position: absolute !important;
    top: 392px !important;
    bottom: 0 !important;
    left: 58.3% !important;
    z-index: 999 !important;
    transform: scale(1.2);
  }

  .logger_text2 {
    color: whitesmoke;
    font-size: 30px;
    font-weight: 500;
    right: -728%;
    position: inherit;
    z-index: 1066;
    top: -167px;
  }
  .dataLogger {
    position: absolute;
    margin-top: 107vh;
    padding-top: 0;
    transform: scale(1.1);
  }
}
@media (min-width: 700px) and (max-width: 900px) {
  .ion-row_d {
    transform: scale(0.7);
    margin-left: -329px;
    margin-top: -89px;
  }
  .logger_col {
    padding-left: 53%;
    margin-top: -59%;
    z-index: 999;
    width: 100% !important;
    transform: scale(0.7);
  }
  .ts_16_d {
    margin-top: 210px;
    position: relative;
    top: -765px;
    bottom: 0;
    left: -9%;
    z-index: 999;
  }
  .btn_r_dl {
    margin-top: -595px;
    margin-left: 725px;
  }
}
@media (min-width: 525px) and (max-width: 699px) {
  .logger_img {
    display: none;
  }
  .ion-row_d {
    margin-top: -369px;
    margin-left: -67px;
    height: 108vh;
    width: 225vw;
    background: linear-gradient(rgba(34, 33, 33, 0.5), rgba(240, 236, 236, 0.8)),
      url(https://res.cloudinary.com/dpysmqax5/image/upload/v1619732023/ambiTag2_1_1_c1azx9.png);
    background-size: 69% 99%;
    background-repeat: no-repeat;
  }
  .logger_col {
    padding-left: 0;
    margin-top: 5%;
    margin-left: -35px;
    z-index: 999;
    width: 100% !important;
    transform: scale(0.8);
  }
  .logger_text2 {
    color: whitesmoke;
    font-size: 30px;
    font-weight: 500;
    right: 61%;
    position: relative;
    z-index: 1066;
    top: -141px;
  }
  .lef3 {
    margin-top: 0px;
    position: relative;
    top: -74px;
    bottom: 0;
    left: 16%;
    z-index: 999;
  }
  .btn_r_dl {
    margin-top: -252px;
    margin-left: 432px;
    transform: scale(0.8);
  }
  .btn_explore_col_mb {
    margin-left: -640px !important;
    margin-top: 226px !important;
  }
}

@media (min-width: 475px) and (max-width: 699px) and (min-height: 970px) and (max-height: 1035px) {
  .logger_img {
    display: none;
  }
  .btn_explore_col_mb {
    margin-left: -640px !important;
    margin-top: 226px !important;
  }
  .ion-row_d {
    margin-top: -369px;
    margin-left: -67px;
    height: 108vh;
    width: 225vw;
    background: linear-gradient(rgba(34, 33, 33, 0.5), rgba(240, 236, 236, 0.8)),
      url(https://res.cloudinary.com/dpysmqax5/image/upload/v1619732023/ambiTag2_1_1_c1azx9.png);
    background-size: 69% 99%;
    background-repeat: no-repeat;
  }
  .logger_col {
    padding-left: 0;
    margin-top: 5%;
    margin-left: -35px;
    z-index: 999;
    width: 100% !important;
    transform: scale(0.8);
  }
  .logger_text2 {
    color: whitesmoke;
    font-size: 30px;
    font-weight: 500;
    right: 61%;
    position: relative;
    z-index: 1066;
    top: -141px;
  }
  .btn_r_dl {
    margin-top: -48%;
    margin-left: 457px;
    transform: scale(0.8);
  }
  .lef3 {
    margin-top: -7%;
    position: relative;
    top: -188px;
    bottom: 0;
    left: 16%;
    z-index: 999;
  }
}

@media (min-width: 400px) and (min-height: 950px) and (max-width: 500px) and (max-height: 1050px) {
  .lef3 {
    margin-top: -22% !important;
    position: relative;
    top: -72px;
    bottom: 0;
    left: 16%;
    z-index: 999;
  }
  .btn_r_dl {
    margin-top: -61% !important;
    margin-left: 432px;
    transform: scale(0.8);
  }
}

@media (min-width: 425px) and (max-width: 500px) {
  .logger_img {
    display: none;
  }
  .btn_explore_col_mb {
    margin-left: -640px !important;
    margin-top: 226px !important;
  }
  .ion-row_d {
    margin-top: -369px;
    margin-left: -67px;
    height: 108vh;
    width: 225vw;
    background: linear-gradient(rgba(34, 33, 33, 0.5), rgba(240, 236, 236, 0.8)),
      url(https://res.cloudinary.com/dpysmqax5/image/upload/v1619732023/ambiTag2_1_1_c1azx9.png);
    background-size: 69% 99%;
    background-repeat: no-repeat;
  }
  .logger_col {
    padding-left: 0;
    margin-top: 5%;
    margin-left: -35px;
    z-index: 999;
    width: 100% !important;
    transform: scale(0.8);
  }
  .logger_text2 {
    color: whitesmoke;
    font-size: 30px;
    font-weight: 500;
    right: 61%;
    position: relative;
    z-index: 1066;
    top: -141px;
  }
  .btn_r_dl {
    margin-top: -24%;
    margin-left: 432px;
    transform: scale(0.8);
  }
  .lef3 {
    margin-top: 21%;
    position: relative;
    top: -188px;
    bottom: 0;
    left: 16%;
    z-index: 999;
  }
}
@media (min-width: 475px) and (max-width: 699px) and (min-height: 1035px) and (max-height: 1050px) {
  .logger_img {
    display: none;
  }
  .btn_explore_col_mb {
    margin-left: -640px !important;
    margin-top: 226px !important;
  }
  .ion-row_d {
    margin-top: -369px;
    margin-left: -67px;
    height: 108vh;
    width: 225vw;
    background: linear-gradient(rgba(34, 33, 33, 0.5), rgba(240, 236, 236, 0.8)),
      url(https://res.cloudinary.com/dpysmqax5/image/upload/v1619732023/ambiTag2_1_1_c1azx9.png);
    background-size: 69% 99%;
    background-repeat: no-repeat;
  }
  .logger_col {
    padding-left: 0;
    margin-top: 5%;
    margin-left: -35px;
    z-index: 999;
    width: 100% !important;
    transform: scale(0.8);
  }
  .logger_text2 {
    color: whitesmoke;
    font-size: 30px;
    font-weight: 500;
    right: 61%;
    position: relative;
    z-index: 1066;
    top: -141px;
  }
  .btn_r_dl {
    margin-top: -48%;
    margin-left: 457px;
    transform: scale(0.8);
  }
  .lef3 {
    margin-top: -10%;
    position: relative;
    top: -188px;
    bottom: 0;
    left: 16%;
    z-index: 999;
  }
}
@media (min-width: 400px) and (max-width: 424px) {
  .logger_img {
    display: none;
  }
  .ion-row_d {
    margin-top: -369px;
    margin-left: -67px;
    height: 108vh;
    width: 225vw;
    background: linear-gradient(rgba(34, 33, 33, 0.5), rgba(240, 236, 236, 0.8)),
      url(https://res.cloudinary.com/dpysmqax5/image/upload/v1619732023/ambiTag2_1_1_c1azx9.png);
    background-size: 69% 99%;
    background-repeat: no-repeat;
  }
  .logger_col {
    padding-left: 0;
    margin-top: 5%;
    margin-left: -35px;
    z-index: 999;
    width: 100% !important;
    transform: scale(0.8);
  }
  .logger_text2 {
    color: whitesmoke;
    font-size: 30px;
    font-weight: 500;
    right: 61%;
    position: relative;
    z-index: 1066;
    top: -141px;
  }
  .lef3 {
    margin-top: 41px;
    position: relative;
    top: -72px;
    bottom: 0;
    left: 16%;
    z-index: 999;
  }
  .btn_r_dl {
    margin-top: -279px;
    margin-left: 432px;
    transform: scale(0.8);
  }
  .btn_explore_col_mb {
    margin-left: -640px !important;
    margin-top: 226px !important;
  }
}

@media (min-width: 400px) and (min-height: 800px) and (max-width: 500px) and (max-height: 970px) {
  .logger_img {
    display: none;
  }
  .ion-row_d {
    margin-top: -369px;
    margin-left: -67px;
    height: 108vh;
    width: 225vw;
    background: linear-gradient(rgba(34, 33, 33, 0.5), rgba(240, 236, 236, 0.8)),
      url(https://res.cloudinary.com/dpysmqax5/image/upload/v1617022418/logger_home_page_tablet_spi7a5.jpg);
    background-size: 69% 99%;
    background-repeat: no-repeat;
  }
  .logger_col {
    padding-left: 0;
    margin-top: 5%;
    margin-left: -35px;
    z-index: 999;
    width: 100% !important;
    transform: scale(0.8);
  }
  .logger_text2 {
    color: whitesmoke;
    font-size: 30px;
    font-weight: 500;
    right: 61%;
    position: relative;
    z-index: 1066;
    top: -141px;
  }
  .lef3 {
    margin-top: 8%;
    position: relative;
    top: -188px;
    bottom: 0;
    left: 16%;
    z-index: 999;
  }
  .btn_r_dl {
    margin-top: -41%;
    margin-left: 430px;
    transform: scale(0.8);
  }
  .btn_explore_col_mb {
    margin-left: -640px !important;
    margin-top: 226px !important;
  }
}

@media (min-width: 355px) and (max-width: 399px) {
  .logger_img {
    display: none;
  }
  .ion-row_d {
    margin-top: -369px;
    margin-left: -67px;
    height: 108vh;
    width: 225vw;
    background: linear-gradient(rgba(34, 33, 33, 0.5), rgba(240, 236, 236, 0.8)),
      url(https://res.cloudinary.com/dpysmqax5/image/upload/v1619732023/ambiTag2_1_1_c1azx9.png);
    background-size: 69% 99%;
    background-repeat: no-repeat;
  }
  .logger_col {
    padding-left: 0;
    margin-top: 5%;
    margin-left: -35px;
    z-index: 999;
    width: 100% !important;
    transform: scale(0.8);
  }
  .logger_text2 {
    color: whitesmoke;
    font-size: 30px;
    font-weight: 500;
    right: 61%;
    position: relative;
    z-index: 1066;
    top: -141px;
  }
  .lef3 {
    margin-top: 0px;
    position: relative;
    top: -74px;
    bottom: 0;
    left: 16%;
    z-index: 999;
  }
  .btn_r_dl {
    margin-top: -252px;
    margin-left: 432px;
    transform: scale(0.8);
  }
  .btn_explore_col_mb {
    margin-left: -640px !important;
    margin-top: 226px !important;
  }
}

@media (min-width: 355px) and (max-width: 399px) and (min-height: 800px) and (max-height: 950px) {
  .lef3 {
    margin-top: -104px;
    position: relative;
    top: -74px;
    bottom: 0;
    left: 16%;
    z-index: 999;
  }
  .btn_r_dl {
    margin-top: -450px;
    margin-left: 419px;
    transform: scale(0.8);
  }
}
@media (min-width: 320px) and (max-width: 354px) {
  .logger_img {
    display: none;
  }
  .ion-row_d {
    margin-top: -369px;
    margin-left: -67px;
    height: 108vh;
    width: 225vw;
    background: linear-gradient(rgba(34, 33, 33, 0.5), rgba(240, 236, 236, 0.8)),
      url(https://res.cloudinary.com/dpysmqax5/image/upload/v1619732023/ambiTag2_1_1_c1azx9.png);
    background-size: 69% 99%;
    background-repeat: no-repeat;
  }
  .logger_col {
    padding-left: 0;
    margin-top: 5%;
    margin-left: -35px;
    z-index: 999;
    width: 100% !important;
    transform: scale(0.8);
  }
  .logger_text2 {
    color: whitesmoke;
    font-size: 30px;
    font-weight: 500;
    right: 61%;
    position: relative;
    z-index: 1066;
    top: -141px;
  }
  .lef3 {
    margin-top: 19px;
    position: relative;
    top: -74px;
    bottom: 0;
    left: 19%;
    z-index: 999;
  }
  .btn_r_dl {
    margin-top: -312px;
    margin-left: 420px;
    transform: scale(0.8);
  }
  .btn_explore_col_mb {
    margin-left: -640px !important;
    margin-top: 226px !important;
  }
}
@media (min-width: 1800px) and (max-width: 2200px) and (min-height: 800px) and (max-height: 1100px) {
  .logger_img {
    margin-top: -3%;
    margin-left: -152px;
    transform: scale(1.3, 1.1);
  }
  .logger_col {
    padding-left: 57%;
    margin-top: -37%;
    z-index: 999;
    width: 100% !important;
    transform: scale(1.3);
  }
  .ts_16_d {
    margin-top: 210px;
    position: relative;
    top: -56rem;
    bottom: 0;
    left: -19%;
    z-index: 999;
    transform: scale(1.5);
  }
  .btn_r_dl {
    margin-top: -30rem;
    margin-left: 101rem;
    z-index: 9999;
  }
}
