.sector,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  transform: translate3d(0px, 0px, 0px);
  transition: all 700ms ease;
}
.bcnText {
  position: relative;
  display: flex;
  top: 43%;
  text-align: center;
  margin-left: 16.5%;
  font-weight: 500;
  font-size: 28px;
}

.footer_dl_row {
  height: 0;
  margin-top: -1rem;
}
.footer_dl_row > .Footer {
  margin-top: -10rem;
  height: 37vh;
}
.beaconsec {
  background-color: black;
  margin: -5px;
  background: linear-gradient(rgba(58, 52, 52, 0.5), rgba(48, 45, 45, 0.8)),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1615273889/WhatsApp_Image_2021-03-07_at_13.37.43_zgl5bf.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  scroll-behavior: smooth;
  transform: translate3d(0px, 0px, 0px);
  transition: all 700ms ease;
}

.beaconimage {
  height: 100vh;
  opacity: 1;
  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1615273889/WhatsApp_Image_2021-03-07_at_13.37.43_zgl5bf.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
body {
  font-family: Arial, Helvetica, sans-serif;
}

.ircardcol {
  margin-left: 4rem;
}
.irrow {
  margin-top: 123px;
}
.imgir {
  position: absolute;
  top: 343px;
}
.irphoto2_b {
  left: -857px;

  margin-top: -45px;
}
.smallphotoir_b {
  transform: scale(0.125);
  position: absolute;
  top: -434px;
  left: -570px;
  z-index: 9999;
}
.subheading {
  font-size: 73px;
}
.irioncol {
  margin-top: -125px;
}
.ircard {
  height: 420px;
  border: 1px solid white;
  border-radius: 3%;
}
.ahtext {
  margin-top: 84px;
}
.nfcheading {
  top: 43%;
}
.irbutton1 {
  width: 145px;
  top: -62px;
}

.drbutton {
  margin-top: 99px;
  text-align: center;
  position: absolute;
  margin-left: 252px;
}
.frbutton1 {
  position: relative;
  left: 37rem;
  top: -58px !important;
}

.irbutton2 {
  margin-top: -50px;
  position: absolute;
  left: 17rem;
  width: 145px;
}
.beaconcolsector {
  background: linear-gradient(
      rgba(253, 249, 249, 0.5),
      rgba(240, 236, 236, 0.8)
    ),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1615273889/WhatsApp_Image_2021-03-07_at_13.37.43_zgl5bf.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* Float four columns side by side */
.column {
  float: left;
  width: 25%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.cardrow {
  margin: 0 -5px;
}

/* Clear floats after the columns */
.cardrow:after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 700px) and (max-width: 950px) {
  .ion-row_b {
    background: white;
    margin-top: 286px;
    margin-left: -781px;
    transform: scale(0.577);
  }
}
/* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.white-card {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  padding: 16px;
  text-align: center;
  background-color: transparent;
  width: 39rem;
  border: none !important;
}

.cards1 {
  margin-left: 236px;
  margin-top: 143px;
}
.cards3 {
  margin-left: 42px;
  margin-top: 95px;
}
.cards2 {
  margin-left: 93px;
  margin-top: 329px;
}
.tx {
  color: whitesmoke;
  font-size: 28px;
  text-align: center;
}

@media (min-width: 2500px) {
  #footer_beacon_sec > .Footer {
    top: -253px !important;
    left: 0 !important;
  }
  .footer_dl_row {
    height: 0;
    margin-top: -7rem;
  }
  .btn_beacon_txt {
    margin-top: -208px;
    transform: scale(1.5);
  }
}
@media (min-width: 300px) and (max-width: 374px) {
  .line_dl_tb {
    font-size: 52px !important;
    width: 141% !important;
    left: -6rem;
    top: -13rem !important;
  }
}

@media (min-width: 374px) and (max-width: 425px) {
  .line_dl_tb {
    font-size: 52px !important;
    width: 141% !important;
    left: -6rem;
    top: -13rem !important;
  }
}

@media (min-width: 425px) and (max-width: 699px) {
  .line_dl_tb {
    font-size: 60px !important;
    width: 141% !important;
    left: -8rem;
    top: -13rem !important;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .rfidText {
    left: -14rem;
    position: relative;
    display: flex;
    top: 43%;
    text-align: center;
    margin-left: 26.5%;
    font-weight: 500;
    font-size: 28px;
  }
  .line-1 {
    position: relative;
    top: 50%;
    left: -5rem;
    width: 34em;
    margin: 0 auto;
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
  }
  .line_dl_tb {
    font-size: 65px !important;
    width: 141% !important;
    left: -18rem;
    top: -13rem !important;
  }
}
.rcolsector {
  height: 100vh;
}
.heading {
  font-size: 120px;
  font-weight: 500;
}
/* Google Fonts */
@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);

/* Global */

.line-1 {
  position: relative;
  top: 50%;
  width: 34em;
  margin: 0 auto;
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}

/* Animation */
.anim-typewriter {
  animation: typewriter 4s steps(64) 1s 1 normal both,
    blinkTextCursor 500ms steps(64) infinite normal;
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 10em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}
.container {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}
.btn_beacon_txt {
  margin-top: -208px;
}
.element {
  height: 100vh;
  scroll-snap-align: center;
}
.element {
  display: flex;
  align-items: center;
  justify-content: center;
}
