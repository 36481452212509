@media (min-width: 2500px) {
  .line_dl {
    font-size: 93px !important;
    width: 100% !important;
    top: -4rem;
  }
  #footer_ed_sec > .Footer {
    top: -253px !important;
    left: 0 !important;
  }
}
@media (min-width: 1023px) and (max-width: 1200px) {
  .loggerText {
    position: relative;
    display: flex;
    margin-top: -9% !important;
    text-align: center;
    margin-left: auto;
    font-weight: 500;
    font-size: 28px;
  }
  #iot_edu_sec {
    left: 0rem;
    margin-top: -3rem;
    min-width: 48rem;
  }
}
@media (min-width: 300px) and (max-width: 375px) {
  #education_sec_heading {
    left: -33rem;
    font-size: 10rem !important;
  }
  .loggerText {
    position: relative;
    display: flex;
    margin-top: -9% !important;
    text-align: center;
    margin-left: 31px;
    font-weight: 500;
    font-size: 28px;
  }
  #iot_edu_sec {
    left: -8rem;
    margin-top: -24rem;
    min-width: 48rem;
  }
  #iot_ed_btn {
    margin-top: -2rem;
    transform: scale(0.8);
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  #education_sec_heading {
    left: -31rem;
    font-size: 10rem !important;
  }
  .loggerText {
    position: relative;
    display: flex;
    margin-top: -9% !important;
    text-align: center;
    margin-left: -1px;
    font-weight: 500;
    font-size: 19px;
  }
  #iot_edu_sec {
    left: -8rem;
    margin-top: -24rem;
    min-width: 48rem;
  }
  #iot_ed_btn {
    margin-top: -2rem;
    transform: scale(0.8);
  }
}

@media (min-width: 425px) and (max-width: 699px) {
  #education_sec_heading {
    left: -31rem;
    font-size: 10rem !important;
  }
  .loggerText {
    position: relative;
    display: flex;
    margin-top: -9% !important;
    text-align: center;
    margin-left: -1px;
    font-weight: 500;
    font-size: 19px;
  }
  #iot_edu_sec {
    left: -3rem;

    margin-top: -24rem;
    min-width: 48rem;
  }
  #iot_ed_btn {
    margin-top: -2rem;
    transform: scale(0.7);
  }
}
@media (min-width: 699px) and (max-width: 1000px) {
  #education_sec_heading {
    left: -11rem;
    font-size: 10rem !important;
  }
  .loggerText {
    position: relative;
    display: flex;
    margin-top: -9% !important;
    text-align: center;
    margin-left: -1px;
    font-weight: 500;
    font-size: 19px;
  }
  #iot_edu_sec {
    left: 1rem;
    margin-top: -14rem;
    min-width: 48rem;
  }
  #iot_ed_btn {
    margin-top: -2rem;
    transform: scale(0.7);
  }
}

.educationimg {
  opacity: 1;
  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1617630530/tab_gif_yotsj7.gif");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.eduimg_sector {
  background: linear-gradient(
      rgba(253, 249, 249, 0.5),
      rgba(240, 236, 236, 0.8)
    ),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1617630530/tab_gif_yotsj7.gif");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.eduimg2 {
  background: linear-gradient(rgba(129, 118, 118, 0.5), rgba(48, 45, 45, 0.8)),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1617630530/tab_gif_yotsj7.gif");
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  background-attachment: fixed !important;
}
