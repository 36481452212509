.Footer {
  position: relative;
  top: -310px;
  left: -10px;
  height: 44vh;
  background: black;
  width: 100vw;
}
.footer_head {
  justify-content: center;
}
.ql_head {
  font-size: 38px;
  font-family: auto;
  text-align: center;
  font-weight: 500;
}
a {
  text-decoration: none;
}
.footergrid {
  margin-top: 32px;
}
.adress_text {
  top: -29px;
}
.menu_foot {
  justify-content: space-around;
  height: 27vh;
  margin-left: 30rem;
}
.menus_quicklink {
  top: 0rem;
  display: flex;
  font-size: 18px;
  flex-direction: column;
  left: -36rem;
  height: 15rem;
  justify-content: space-between;
  z-index: 999999;
}

.ed {
  margin-left: -25px;
}
.menus_quicklink2 {
  top: -15rem;
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  left: 0rem;
  flex-direction: column;
  height: 12rem;
  z-index: 999999;
}

.secpages_heading {
  top: -21rem;
  left: 55.5rem;
}

.con_footer {
  margin-left: -21px;
}
.menus_quicklink3 {
  top: 13rem;
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  position: absolute;
  left: 8rem;

  z-index: 999999;
}
.menus_quicklink4 {
  top: -27rem;
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  left: 24rem;
  flex-direction: column;
  height: 9rem;
}

.prodpagesf_heading {
  top: -33rem;
  left: 93rem;
}
.shop_footer {
  position: relative;
  left: -28px;
}
.quicklink1_heading {
  top: -6rem;
  left: 25rem;
}
.add_heading {
  font-size: 34px;
}
.add_text {
  font-size: 15px;
}
.home {
  height: 0;
}
.Address_row {
  margin-top: -26rem;
  margin-left: -385rem;
  font-family: auto;
  display: flex;
  flex-flow: wrap-reverse;
  transform: scale(1.3);
  flex-direction: column;
}

.f_l {
  width: 138%;
}
.logo_footer {
  margin-left: 7rem;
}

@media (min-width: 2500px) {
  .menus_quicklink {
    top: 7rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    left: -16.5rem;
    transform: scale(1.5);
    z-index: 999999;
  }
  .add_text {
    font-size: 2rem;
  }
  .quicklink1_heading {
    top: -3rem;
    left: 88rem;
    transform: scale(1.3);
  }
  .Address_row {
    margin-top: -34rem;
    font-family: auto;
    display: flex;
    flex-flow: wrap-reverse;
    flex-direction: column;
    transform: scale(1.3);
    margin-left: -355rem;
  }
  .menus_quicklink3 {
    top: 17rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    position: absolute;
    margin-left: 7rem;
    z-index: 999999;
    transform: scale(1.5);
  }
  .menus_quicklink2 {
    top: -10rem;
    z-index: 999999;
    display: flex;
    justify-content: space-around;
    font-size: 24px;
    left: 27rem;
    transform: scale(1.4);
  }
  .secpages_heading {
    top: -19rem;
    left: 122.5rem;
    transform: scale(1.3);
  }

  .prodpagesf_heading {
    top: -31rem;
    left: 155rem;
    transform: scale(1.3);
  }
  .menus_quicklink4 {
    top: -24rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    left: 42rem;
    flex-direction: column;
    height: 9rem;
    transform: scale(1.3);
  }
  .add_heading {
    font-size: 5rem;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .menus_quicklink {
    top: -70px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    left: 269px;
    z-index: 999999;
  }
  .menus_quicklink3 {
    top: 67px;
    display: flex;
    z-index: 999999;
    justify-content: space-around;
    font-size: 18px;
    position: absolute;
    left: 400px;
  }
  .menus_quicklink2 {
    top: 35px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    z-index: 999999;
    left: -240px;
  }
}

@media (min-width: 320px) and (max-width: 375px) and (min-height: 600px) and (max-height: 1050px) {
  .f_l {
    transform: scale(2.5);
    margin-left: 37px;
  }
  .Address_row {
    margin-top: -21%;
    font-family: auto;
    display: flex;
    flex-flow: wrap-reverse;
    flex-direction: column;
    transform: scale(0.6);
  }
  .adress_text {
    top: -29px;
    min-width: 212px !important;
    left: 30%;
  }
  .adress {
    right: 31px;
  }
  .menus_quicklink3 {
    top: 85px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    position: absolute;
    left: 23%;
    transform: scale(0.7);
    z-index: 999999;
  }
}

@media (min-width: 375px) and (max-width: 550px) and (min-height: 600px) and (max-height: 1050px) {
  .f_l {
    transform: scale(2.5);
    margin-left: 74px;
  }
  .Address_row {
    margin-top: -25%;
    font-family: auto;
    display: flex;
    flex-flow: wrap-reverse;
    flex-direction: column;
    transform: scale(0.6);
  }
  .adress_text {
    top: -28px;
    min-width: 212px !important;
    left: 31%;
  }

  .menus_quicklink3 {
    top: 85px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    position: absolute;
    left: 19%;
    z-index: 999999;
    transform: scale(0.7);
  }
}

@media screen and (max-width: 599px) {
  .Footer {
    position: relative;
    top: -310px;
    left: -10px;
    height: 50vh;
    background: black;
    width: 100vw;
  }
  .add_text {
    font-size: 23px;
  }
  .logo_footer {
    margin-left: -5rem;
    transform: scale(0.7);
  }
  .menus_quicklink3 {
    top: 3.5rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    position: absolute;
    left: 20rem;
    transform: scale(0.7);
    z-index: 999999;
    min-width: 26rem;
  }
  .Address_row {
    margin-top: -4rem;
    margin-left: -42rem;
    font-family: helvetica;
    display: flex;
    flex-flow: wrap-reverse;
    flex-direction: column;
    transform: scale(0.6);
  }
  .adress_text_mb {
    top: -1rem;
    min-width: 58rem;
    left: 25rem;
  }
  .menus_quicklink2 {
    transform: scale(0.8);
    top: -19rem;
    left: 11rem;
    z-index: 999999;
  }
  .prodpagesf_heading {
    top: -27.5rem;
    left: 26rem;
    transform: scale(0.6);
    min-width: 18rem;
  }
  .menus_quicklink4 {
    top: -23.5rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    left: 7.5rem;
    flex-direction: column;
    height: 9rem;
    transform: scale(0.7);
    z-index: 999999;
  }

  .secpages_heading {
    top: -16rem;
    left: 13rem;
    transform: scale(0.6);
  }
  .menus_quicklink {
    top: 4rem;
    display: flex;
    font-size: 18px;
    flex-direction: column;
    left: -17.5rem;
    height: 15rem;
    justify-content: space-between;
    transform: scale(0.8);
    min-width: 10rem;
    z-index: 999999;
  }
  .secpages_heading {
    top: -15.5rem;
    left: 13rem;
    transform: scale(0.6);
    min-width: 33rem;
  }
  .menu_foot {
    justify-content: space-around;
    height: 27vh;
    margin-left: 4rem;
  }
  .quicklink1_head_txt {
    font-size: 28px;
  }
  .quicklink1_heading {
    top: -1.5rem;
    left: -0.5rem;
    transform: scale(0.6);
  }
  .Footer {
    height: 56vh;
  }
}

@media screen and (max-width: 424px) {
  .logo_footer {
    margin-left: -8rem;
    transform: scale(0.6);
  }
  .quicklink1_heading {
    top: -1.5rem;
    left: -3rem;
    transform: scale(0.6);
  }
  .adress_text_mb {
    top: -1rem;
    min-width: 47rem;
    left: 25rem;
  }
  .secpages_heading {
    top: -16rem;
    left: 9.5rem;
    min-width: 17rem;
    transform: scale(0.6);
  }

  .menus_quicklink2 {
    transform: scale(0.7);
    top: -12rem;
    left: -6.5rem;
    z-index: 999999;
  }
  .prodpagesf_heading {
    top: -27.5rem;
    left: 21.5rem;
    transform: scale(0.6);
    min-width: 18rem;
  }
  .menus_quicklink4 {
    top: -23.5rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    left: 5rem;
    flex-direction: column;
    height: 9rem;
    transform: scale(0.7);
  }

  .menus_quicklink3 {
    top: 3.5rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    position: absolute;
    left: 15rem;
    transform: scale(0.7);
    min-width: 26rem;
    z-index: 999999;
  }
}

@media screen and (max-width: 374px) {
  .logo_footer {
    margin-left: -5.5rem;
    transform: scale(0.6);
  }
  .menus_quicklink {
    top: 3rem;
    display: flex;
    font-size: 18px;
    flex-direction: column;
    left: -17.5rem;
    height: 15rem;
    justify-content: space-between;
    transform: scale(0.7);
    z-index: 999999;
  }
  .menus_quicklink3 {
    top: 3rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    position: absolute;
    left: 10rem;
    transform: scale(0.7);
    min-width: 26rem;
    z-index: 999999;
  }
  .secpages_heading {
    top: -15rem;
    left: 1.5rem;
    min-width: 17rem;
    transform: scale(0.6);
  }
  .menus_quicklink2 {
    transform: scale(0.7);
    top: -18.5rem;
    left: 2.5rem;
    z-index: 999999;
  }
  .prodpagesf_heading {
    top: -34rem;
    left: 11.5rem;
    transform: scale(0.4);
    min-width: 18rem;
  }
  .menus_quicklink4 {
    top: -37rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    left: 13.5rem;
    flex-direction: column;
    height: 9rem;
    transform: scale(0.7);
    z-index: 999999;
  }

  .adress_text_mb {
    top: -1rem;
    min-width: 48rem;
    left: 25rem;
  }
  .adress {
    right: -5rem;
  }
}
@media (min-width: 1800px) and (max-width: 2200px) and (min-height: 800px) and (max-height: 1100px) {
  .Address_row {
    margin-top: -6rem;
    margin-left: -385rem;
    font-family: auto;
    display: flex;
    flex-flow: wrap-reverse;
    transform: scale(1.3);
    z-index: -99999999;
    flex-direction: column;
}
  .menu_foot {
    justify-content: space-around;
    height: 27vh;
    margin-left: 78rem;
    transform: scale(1.2);
    z-index: 999999999;
    position: absolute;
  }
  .menus_quicklink {
    top: 1rem;
    display: flex;
    font-size: 18px;
    flex-direction: column;
    left: -16rem;
    height: 15rem;
    justify-content: space-between;
    transform: scale(1.2);
    z-index: 999999;
  }
  .menus_quicklink2 {
    top: -14rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    left: 18rem;
    flex-direction: column;
    height: 12rem;
    transform: scale(1.2);
    z-index: 999999;
  }

  .quicklink1_heading {
    top: -6rem;
    left: 5rem;
    transform: scale(1.2);
  }
  .menus_quicklink3 {
    top: 4rem;
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    position: absolute;
    left: -45rem;
    transform: scale(1.5);
    z-index: 999999;
  }
  .secpages_heading {
    top: -21rem;
    left: 37rem;
    transform: scale(1.2);
  }
  .adress_text {
    top: -20px;
    transform: scale(1.2);
    left: 9.5rem;
  }
}
