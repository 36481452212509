.app_collap {
  margin: 0 auto;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  padding: 3rem 1rem 1rem;

  &__text {
    width: 100%;
  }

  &__collapse {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);

    &--gradient {
      .app__content {
        border-color: transparent;
      }

      &:after {
        pointer-events: none;
        transition: opacity 300ms;
        will-change: opacity;
        opacity: 1;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent 50%, white);
      }
    }

    &--active {
      &:after {
        opacity: 0;
      }
    }
  }

  &__content {
    border: 1px solid transparent;
    padding: 1rem 0;
    margin-bottom: 3rem;
    position: relative;
    transition: 300ms;
  }

  &__toggle {
    width: 100%;
    margin: 1rem 0;
    border: 1px solid transparent;
    text-transform: uppercase;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0 2px 4px #888;
    background-color: #fff;
    transition: background-color 300ms;
    &--active {
      background-color: #fafafa;
    }
  }

  &__toggle-text {
    font-size: inherit;
  }

  &__button {
    display: block;
    width: 20rem;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }

  &__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }
}

.ziprow {
  display: flex;
  justify-content: center;
  margin-left: -29vw;
  margin-top: 1vh;
}
.icon {
  width: 1em;
  height: 1em;
  transition: transform 300ms cubic-bezier(0, 1, 0, 1);
  will-change: transform;
  &--expanded {
    transform: rotateZ(-179.99deg);
  }
}

.rotate90 {
  transform: rotateZ(90deg);
}
.btn_collap {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.btn_row_c {
  font-size: 3rem;
  font-weight: 500;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.col_pctxt {
  font-size: 2rem;
  font-weight: 300;
  margin-left: 0rem;
  padding-left: 0rem;
}
.drp_icn_cirp {
  margin-left: 131rem;
  margin-top: -1rem;
}
.inner_text_irp {
  left: 5rem;
  padding-left: 0rem;
}
@media screen and (max-width: 699px) {
  #hght {
    font-size: 3rem;
    font-weight: 500;
    width: 100vw;
    height: 14vh;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 425px) {
  .btn-down {
    display: flex;
    max-width: 51rem;
    text-align: center;
    justify-content: space-between;
  }
  .filename_btn {
    min-width: 100%;
    margin-left: -13rem;
  }
}

@media screen and (max-width: 374px) {
  .filename_btn {
    min-width: 76%;
    margin-left: -1rem;
  }
}

@media screen and (max-width: 1024px) {
  .drp_icn_cirp {
    margin-left: 85rem;
    margin-top: -5rem;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .drp_icn_cirp {
    margin-left: 131rem;
    margin-top: -4rem;
  }
}
@media (min-width: 1800px) and (max-width: 2200px) and (min-height: 800px) and (max-height: 1100px) {
  .ziprow {
    display: flex;
    justify-content: center;
    margin-left: -41vw;
    margin-top: 1vh;
  }
  .dl_img1_product {
    background: url(https://res.cloudinary.com/dpysmqax5/image/upload/v1619648086/ambiTag_1_t1czku.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
    margin-top: 10rem;
  }
}
