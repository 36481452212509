/* div.topheader {
    position:sticky;
    max-width:45%;
    max-height:45%;
    top:50%;
    left:50%;
    overflow:visible;
} */

.Name{
  border-bottom: 1px solid;
}
.basketCount{
  margin-left: -7rem;
  font-size: 2rem;
  background: white;
  border-radius: 50%;
  padding: 0.5rem;
  height: 3rem;
  margin-top: -1rem;
  font-weight: 600;
}
.topheader {
  background: #a9a9a9;
  height: 7vh;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
  overflow: hidden;
}
.dspname {
  top: -1rem;
  font-size: 13px;
  color: wheat;
  font-weight: 700;
}
.logo1 {
  width: 176px;
  position: absolute;
  padding-left: -42px;
  z-index: 99999;
  margin-left: -108px;
  top: 9px;
}
.row_logo_t {
  justify-content: center;
}
@media (min-width: 2500px) {
  .logo1 {
    transform: scale(1.2);

    margin-top: 18px;
  }
  .sidebar_top_col {
    transform: scale(1.3);
    left: -22px;
    top: 11px;
  }
}
.sidebar_top_row {
  justify-content: flex-end;
}
.sidebar_top_col {
  display: flex;
  justify-content: space-around;
}
@media (min-width: 1800px) and (max-width: 2200px) and (min-height: 800px) and (max-height: 1100px) {
  .logo1 {
    transform: scale(1.2);
    margin-top: 5px;
  }
  .sidebar_top_col {
    transform: scale(1.3);
    left: -22px;
    top: 11px;
  }
}

@media (min-width: 320px) and (max-width: 700px) {
  .shoping_icon {
    display: none !important;
  }
  .person_icon {
    display: none !important;
  }
}
