.header {
  width: 350px;
}
.lg {
  justify-content: center;
  text-align: center;
  display: flex;

  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1619385941/SceneSetup1_1_wqqjgh.png");
  margin-left: -1rem;

  background-size: 100% 100%;
  height: 100vh !important;
  width: 100vw;
}
.img_alignhome {
  margin-left: -168px;
  margin-top: -60px;
}
.w_714 {
  width: 714px;
}
@media (min-width: 2500px) {
  .logo {
    margin-left: -50%;
    transform: scale(1.4);
  }
}
.nav1 {
  margin-left: -132px;
  margin-top: -31px;
}

@media (min-width: 1800px) and (max-width: 2200px) and (min-height: 800px) and (max-height: 1100px) {
  .lg {
    justify-content: center;
    text-align: center;
    display: flex;

    background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1619385941/SceneSetup1_1_wqqjgh.png");
    margin-left: -1rem;

    background-size: 100% 100%;
    height: 100vh !important;
    width: 101vw;
  }
}

@media screen and (max-width: 800px) {
  .lg {
    justify-content: center;
    text-align: center;
    display: flex;

    background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1619821432/lol_bdemro.png");
    margin-left: -1rem;

    background-size: 100% 100%;
    height: 100vh !important;
    width: 100vw;
  }
}
