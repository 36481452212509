.white-text {
  color: whitesmoke;
}
.contactbodyrow {
  background: black;
  height: 100vh;
  margin: -5px;
}
.textc {
  color: whitesmoke;
  font-size: 57px;
  font-family: -webkit-body;
  font-weight: 800;
  float: right;
  margin-top: 114px;
  margin-right: -7rem;
}
.contact_footer_cn > .Footer {
  position: relative;
  top: 18rem;
  left: -10px;
  height: 37vh;
  background: black;
  width: 100vw;
}
.lorem {
  margin-top: 188px;
  left: -283px;
}
.contact_footer {
  position: absolute;
  margin-top: 249px;
  position: relative;
  height: 25vh;
  background: black;
  width: 100vw;
}
.white-square {
  position: absolute;
  right: 162px;
  background: white;
  border: 1px solid white;
  border-radius: 9%;
  top: 189px;
  height: 70vh;
}
.phoneicon {
  font-size: 6rem !important;
}
.contactdetails {
  top: 68px;
  left: 147px;
  top: 67px;
  font-weight: 500;
}
.phnnumber {
  top: 92px;
  left: 48px;
}
.phn1 {
  font-size: 1.4rem;
}
.phn2 {
  padding-left: 75px;
  position: absolute;
  width: 20rem;
  font-size: 1.4rem;
}
.email {
  top: 151px;

  left: 142px;
}
.emailicon {
  font-size: 6rem !important;
}
.emailText {
  margin-left: 0rem;
  position: absolute;
  transform: scale(1.5);
}
.location {
  top: 222px;
  left: 144px;
}
.locationicon {
  font-size: 6rem !important;
}

.locationText {
  margin-left: 7px;
  margin-top: 1rem;
  float: left;
  transform: scale(1.5);
}
.textl {
  width: 373px;
}
.formcol {
  top: 50px;
}
@media (min-width: 2500px) and (max-width: 2700px) {
  .white-square {
    transform: scale(1.5);
    position: absolute;
    right: 162px;
    background: white;
    border: 1px solid white;
    border-radius: 9%;
    top: 27rem;
    height: 50rem;
    width: 37rem !important;
  }
  .white-square {
    transform: scale(1.7);
    position: absolute;
    right: 33rem;
    background: white;
    border: 1px solid white;
    border-radius: 9%;
    top: 31rem;
    height: 50rem;
    width: 37rem !important;
  }
  .contact_footer_cn > .Footer > .footergrid > .menu_foot > .menus_quicklink2 {
    top: -11rem;
    display: flex;
    justify-content: space-around;
    font-size: 24px;
    left: 10rem;
    transform: scale(1.3);
  }
  .textc {
    color: whitesmoke;
    font-size: 57px;
    font-family: -webkit-body;
    font-weight: 800;
    float: right;
    margin-top: 114px;
    margin-right: -7rem;
    transform: scale(1.5);
  }
  .lorem {
    margin-top: 36rem;
    left: -9rem;
    transform: scale(1.5);
  }
  .contactbodyrow {
    background: black;
    height: 120vh;
    margin: -5px;
  }
  .textl {
    width: 373px;
    font-size: 2rem;
    margin-left: -6rem;
  }
  .contact_footer_cn > .Footer {
    position: relative;
    top: 18rem;
    left: -10px;
    height: 37vh;
    background: black;
    width: 101vw;
  }
}

@media screen and (max-width: 699px) {
  .white-square {
    position: absolute;
    right: 2rem;
    background: white;
    border: 1px solid white;
    border-radius: 9%;
    top: 69rem;
    height: 54vh;
    min-width: 39rem;
  }
  .lorem {
    margin-top: 0rem;
    left: 0;
    transform: scale(0.9);
    min-width: 99%;
  }
  .colcontact {
    transform: scale(0.8);
    min-width: 70%;
    top: -4rem;
    left: 4rem;
  }
  .contact_footer {
    height: 25vh;
    background: black;
    width: 100vw;
    margin-top: 59rem;
  }
  .contact_footer_cn > .Footer {
    position: relative;
    top: 62rem;
    left: -10px;
    height: 48vh;
    background: black;
    width: 100vw;
  }
  .contact_footer_cn > .Footer > .footergrid > .menu_foot > .menus_quicklink3 {
    top: 1.5rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    position: absolute;
    left: 20rem;
    transform: scale(0.7);
    min-width: 26rem;
  }
}
@media screen and (max-width: 424px) {
  .colcontact {
    transform: scale(0.8);
    min-width: 76%;
    top: -4rem;
    left: 3rem;
  }
  .white-square {
    position: absolute;
    right: -1rem;
    background: white;
    border: 1px solid white;
    border-radius: 9%;
    top: 68rem;
    height: 54vh;
    min-width: 39rem;
  }
  .contact_footer_cn > .Footer > .footergrid > .menu_foot > .menus_quicklink3 {
    top: 1.5rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    position: absolute;
    left: 15rem;
    transform: scale(0.7);
    min-width: 26rem;
  }
}
@media screen and (max-width: 374px) {
  .colcontact {
    transform: scale(0.8);
    min-width: 88%;
    top: -4rem;
    left: 2rem;
  }
  .white-square {
    position: absolute;
    right: -4rem;
    background: white;
    border: 1px solid white;
    border-radius: 9%;
    top: 69rem;
    height: 54vh;
    min-width: 39rem;
  }
  .contact_footer_cn > .Footer > .footergrid > .menu_foot > .menus_quicklink3 {
    top: 0.5rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    position: absolute;
    left: 10rem;
    transform: scale(0.7);
    min-width: 26rem;
  }
}

@media (min-width: 1800px) and (max-width: 2200px) and (min-height: 800px) and (max-height: 1100px) {
  .contact_footer_cn > .Footer {
    position: relative;
    top: 25rem;
    left: 10px;
    height: 37vh;
    background: black;
    width: 100vw;
  }
  .cntn_contact {
    margin-left: 3.5rem;
    transform: scale(1.3);
  }
  .lorem {
    margin-top: 33rem;
    left: -14rem;
    transform: scale(1.4);
  }
}
