.login {
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_container {
  width: 350px;
  box-shadow: 2px lightgrey;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 0.5px solid lightgrey;
  margin-top: 4rem;
}

.login_container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}

.login_container > form > h5 {
  margin-bottom: 5px;
}

.login_container > form > input {
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 98%;
}

.login_container > p {
  margin-top: 15px;
  font-size: 12px;
}

.login_SigninButton {
  background: #1bb8cc;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: whitesmoke;
  border-radius: 2px;
  height: 30px;
  width: 100%;
}

.login_RegisterButton {
  border: 1px solid;
  border-color: darkgray;
  border-radius: 2px;
  height: 30px;
  width: 100%;
  margin-top: 30px;
}
.red {
  color: red;
}
.inside {
  text-align: center;
  position: relative;
}
.span_logins {
  display: inline-block;
  padding: 0 8px 0 7px;
  color: #767676;
}
.span_logins:before,
.span_logins:after {
  border-top: 1px solid lightgrey;
  display: block;
  color: #767676;
  height: 8px;
  content: " ";
  width: 37%;
  position: absolute;
  left: 0;
  top: 1em;
  z-index: 2;
}
.span_logins:after {
  right: 0;
  left: auto;
}
.inside > .span_logins > h5 {
  padding-top: 10px;
  color: #767676;
  font-size: 12px;
  font-weight: 400;
}
.inside {
  width: 350px;
}

.register__alreadyAccount {
  border-top: 1px solid lightgray;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-top: 20px;
  font-size: 12px;
  text-align: center;
}

.register__alreadyAccount > strong {
  color: #0066c0;
  cursor: pointer;
}
