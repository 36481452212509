.sector,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

#pos_left {
  left: -12rem;
}
#pos_left_an {
  left: -15rem;
}
body {
  transform: translate3d(0px, 0px, 0px);
  transition: all 700ms ease;
}
.irbutton1,
.irbutton2,
.frbutton1 {
  z-index: 999999;
}
.irphoto1 {
  transform: scale(1.2, 1.2);
  position: absolute;
  top: -65rem;
  left: -67rem;
  width: 54rem;
  z-index: 9999;
  height: 54rem;
}
.white-text {
  color: whitesmoke !important;
}

.frphoto1 {
  position: absolute;
  top: -59rem;
  left: -82rem;
  z-index: 9999;
  height: 44rem;
}

.frphoto2 {
  position: absolute;
  top: -67rem;
  left: -103rem;
  z-index: 9999;
  height: 47rem;
}

.rfidimagesec {
  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1619202926/rfid_lmpslo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  scroll-behavior: smooth;
  transform: translate3d(0px, 0px, 0px);
  transition: all 700ms ease;
  overflow: hidden;
}
.tocursor {
  cursor: pointer;
}
.sector {
  background-color: black;
  margin: -5px;
  background: linear-gradient(rgba(129, 118, 118, 0.5), rgba(48, 45, 45, 0.8)),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1619202926/rfid_lmpslo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  scroll-behavior: smooth;
  transform: translate3d(0px, 0px, 0px);
  transition: all 700ms ease;
  overflow: hidden;
}
.elementend {
  scroll-snap-align: center !important;
}

.rfidimage {
  height: 100vh;
  opacity: 1 !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
body {
  font-family: Arial, Helvetica, sans-serif;
}
.rfidText {
  position: relative;
  display: flex;
  top: 43%;
  text-align: center;
  margin-left: 23.5%;
  font-weight: 500;
  font-size: 28px;
}
.ir_Text {
  position: relative;
  display: flex;
  top: 43%;
  text-align: center;
  margin-left: 26.5%;
  font-weight: 500;
  font-size: 28px;
}
.ircardcol {
  margin-left: 4rem;
}
.irrow {
  margin-top: 123px;
}
.imgir {
  position: absolute;
  top: 748px !important;
  left: 868px;
}
.imgir_an1 {
  position: absolute;
  top: 570px !important;
  left: 457px;
}
.imgir2 {
  top: 750px;
  position: absolute;
  left: 868px;
}
.bgimg_row {
  background: linear-gradient(
      rgba(253, 249, 249, 0.5),
      rgba(240, 236, 236, 0.8)
    ),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1616531628/office_attendance_mwgxgy.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  background-attachment: fixed !important;
}
.irphoto2 {
  position: absolute;
  top: -78rem;
  left: -97rem;
  z-index: 9999;
  height: 69rem;
}
.linear_ant {
  position: absolute;
  top: -66rem;
  left: -100.5rem;
  height: 43rem;
  z-index: 9999;
}
.circular_ant {
  position: absolute;
  top: -44rem;
  left: -39.5rem;
  height: 43rem;
  z-index: 9999;
}
.white-cardapp {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  padding: 16px;
  text-align: center;
  background-color: white;
  width: 271px;
  height: 341px;
}
.smallphotoir {
  transform: scale(1.2, 1.2);

  position: absolute;
  top: -502px;
  left: -585px;
  z-index: 9999;
}
.slides_abt {
  height: 100%;
  width: 100%;
  transform: scale(0.7);
  top: -5rem;
}
.footer_row_sec_r > .Footer {
  position: relative;
  top: -26rem;
  left: -10px;
  height: 38vh;
  background: black;
  width: 100vw;
}
.bg_an {
  background: linear-gradient(rgba(129, 118, 118, 0.5), rgba(48, 45, 45, 0.8)),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1615709108/A6_1_ao6ynz.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  background-attachment: fixed !important;
  scroll-behavior: smooth;
  transform: translate3d(0px, 0px, 0px);
  transition: all 700ms ease;
  overflow: hidden;
}

.bg_an0 {
  background: linear-gradient(
      rgba(253, 249, 249, 0.5),
      rgba(240, 236, 236, 0.8)
    ),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1615709888/A3_ii7wyn.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  background-attachment: fixed !important;
  scroll-behavior: smooth;
  transform: translate3d(0px, 0px, 0px);
  transition: all 700ms ease;
  overflow: hidden;
}
.smallphotoir_an1 {
  transform: scale(1.2, 1.2);
  position: absolute;
  top: -324px;
  left: -178px;
  z-index: 9999;
}
.subheading {
  font-size: 73px !important;
}
.irioncol {
  margin-top: -125px !important;
}
.ircard {
  height: 420px;
  border: 1px solid white;
  border-radius: 3%;
}
.ahtext {
  margin-top: 84px;
}
.nfcheading {
  top: 43% !important;
}
.irbutton1 {
  width: 145px;
  top: -62px;
}
.antext {
  margin-left: 30%;
  position: relative;
  display: flex;
  top: 43%;
  text-align: center;

  font-weight: 500;
  font-size: 28px;
}
.drbutton {
  margin-top: 99px;
  text-align: center;
  position: absolute;
  margin-left: 252px;
}
.frbutton1 {
  position: relative;
  left: 37rem;
  top: -58px !important;
}
.frtext {
  position: absolute;
  left: 72px;
  top: 52%;
}
.irbutton2 {
  margin-top: -50px;
  position: absolute;
  left: 17rem;
  width: 145px;
}
.rcolsector {
  background: linear-gradient(
      rgba(253, 249, 249, 0.5),
      rgba(240, 236, 236, 0.8)
    ),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1619202926/rfid_lmpslo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.ray {
  background: linear-gradient(
      rgba(253, 249, 249, 0.5),
      rgba(240, 236, 236, 0.8)
    ),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1615274631/M6_d0z0ac.jpg") !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.incolsector {
  background: linear-gradient(rgba(71, 69, 69, 0.5), rgba(48, 45, 45, 0.8)),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1615274631/M6_d0z0ac.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.irhead {
  position: relative;

  width: 34em;
  margin: 0 auto;
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
  top: 97px;
}

/* Float four columns side by side */
.column {
  float: left;
  width: 25%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.cardrow {
  margin: 0 -5px;
}

/* Clear floats after the columns */
.cardrow:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.white-card {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  padding: 16px;
  text-align: center;
  background-color: transparent;
  width: 39rem;
  border: none !important;
}

.cards1_sec_r {
  margin-left: 236px;

  margin-top: -264px;
}
.cards3_sec_r3 {
  margin-left: -55px;

  margin-top: -269px;
}
.cards2_sec_r2 {
  margin-left: 93px;
  margin-top: -88px;
}
.footer_row_sec_r {
  margin-top: -29px;
}
.tx {
  color: whitesmoke;
  font-size: 28px;
  text-align: center;
}
.text1 {
  position: absolute;
  text-align: center;
  margin-top: 28px;

  margin-left: -18px;

  width: 281px;
}

.text2 {
  position: absolute;
  margin-left: -135px !important;
  margin-top: -424px !important;
  text-align: center;
}
.text3 {
  position: absolute;
  margin-left: -307px;
  text-align: center;
  margin-top: 28px;
}
.col_navbutton {
  top: -471px;
  left: 123px;
  /* float: left; */
  z-index: 99999;
}
.frsector_row {
  background: linear-gradient(rgba(129, 118, 118, 0.5), rgba(48, 45, 45, 0.8)),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1615656942/cc_qvbk0t.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  background-attachment: fixed !important;
}
.frsector_row2 {
  background: linear-gradient(
      rgba(253, 249, 249, 0.5),
      rgba(240, 236, 236, 0.8)
    ),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1615656942/cc_qvbk0t.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  background-attachment: fixed !important;
}
.navigation_next {
  color: whitesmoke;
  font-size: 78px;
  right: 0px !important;
  z-index: 9999999;
  top: 225px;
  margin-left: 76vw;
  position: absolute;
  display: flex;
}
.image_inside_app {
  display: flex;
  object-fit: contain;
  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1616753954/office_attendance_1_y3haac.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
.image_inside_app_jewellery {
  display: flex;
  object-fit: contain;
  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1616753957/Untitled-1_k0fa9a.png") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
.image_inside_app_school {
  display: flex;
  object-fit: contain;
  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1616768556/school_bgc7fq.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}

.image_inside_app_weapons {
  display: flex;
  object-fit: contain;
  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1616768509/weapons_abime3.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
.image_inside_app_warehouse {
  display: flex;
  object-fit: contain;
  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1616768508/warehouse_wp40dx.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
.image_inside_app_liquor {
  display: flex;
  object-fit: contain;
  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1616753955/liquor_eylrae.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
.navigation_prev {
  margin-top: -17rem !important;
}

.frtext_n {
  position: absolute;
  left: -18rem;
  top: 52%;
}

.rcolsector {
  height: 100vh;
}
.heading {
  font-size: 120px !important;
  font-weight: 500;
}

@media (min-width: 700px) and (max-width: 950px) {
  .rfid_img {
    z-index: 999;
    margin-left: -162%;
    margin-top: -70%;
    transform: scale(0.7, 1);
  }
  .rfid_col {
    padding-left: 62%;
    margin-top: -143%;
    z-index: 999;
    transform: scale(0.7);
  }
  .lef {
    margin-top: 210px;
    position: relative;
    top: -634px;
    bottom: 0;
    left: 47%;
    z-index: 999;
    text-align: left;
    transform: scale(0.6);
  }
}
/* Google Fonts */
@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);

/* Global */

.line-1 {
  position: relative;
  top: 50%;
  width: 34em;
  margin: 0 auto;
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}

/* Animation */
.anim-typewriter {
  animation: typewriter 4s steps(64) 1s 1 normal both,
    blinkTextCursor 500ms steps(64) infinite normal;
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 10em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}
.container {
  height: 100vh;

  scroll-snap-type: y mandatory;
}

.element {
  height: 100vh;
  scroll-snap-align: center;
}
.element {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 2500px) {
  .line-1 {
    position: relative;

    width: 34em;
    margin: 0 auto;
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
    transform: scale(1.5);
    top: 35%;
  }

  .rfidText {
    position: relative;
    display: flex;
    top: 52%;
    text-align: center;
    margin-left: 1064px !important;
    font-weight: 500;
    font-size: 28px;
    transform: scale(1.5);
  }
  .ircardcol {
    margin-left: 646px !important;
    transform: scale(1.5);
  }
  .ircardcol2 {
    transform: scale(1.5);
  }
  .frtext {
    position: absolute;
    left: -5%;
    top: 108%;
  }
  .antext {
    margin-left: 1129px;

    position: relative;
    display: flex;
    top: 43%;
    text-align: center;

    font-weight: 500;
    font-size: 28px;
    transform: scale(1.5);
  }
  .bg_screen {
    top: 12%;
  }
  .cards1_sec_r {
    margin-left: 385px;
    margin-top: -264px;
  }
  .slides_abt {
    transform: scale(1.2);
  }
  .text2 {
    position: absolute;
    margin-left: -281px !important;
    margin-top: -424px !important;
    text-align: center;
  }
  .text3 {
    position: absolute;
    margin-left: -470px;
    text-align: center;
    margin-top: 28px;
  }
  .navigation_next {
    color: whitesmoke;
    font-size: 78px;

    z-index: 9999999;
    top: 431px;
    margin-left: 57vw;
    position: absolute;
    display: flex;
  }
  .footer_row_sec_r {
    margin-top: 103px;
  }
  .ir_Text {
    position: relative;
    display: flex;
    top: 43%;
    text-align: center;
    margin-left: 31.5%;
    font-weight: 500;
    font-size: 38px;
  }
  .footer_row_sec_r > .Footer > .footergrid > .menu_foot > .menus_quicklink2 {
    top: -11rem;
    display: flex;
    justify-content: space-around;
    font-size: 24px;
    left: 10rem;
    transform: scale(1.3);
  }
}

@media (min-width: 1800px) and (max-width: 2200px) and (min-height: 800px) and (max-height: 1100px) {
  .rfidText {
    position: relative;
    display: flex;
    top: 43%;
    text-align: center;
    margin-left: 30.5%;
    font-weight: 500;
    transform: scale(1.2);
    font-size: 28px;
  }
  .text1 {
    position: absolute;
    text-align: center;
    margin-top: 28px;
    margin-left: -78px;
    width: 281px;
  }
  .text2 {
    position: absolute;
    margin-left: -192px !important;
    margin-top: -424px !important;
    text-align: center;
  }
  .text3 {
    position: absolute;
    margin-left: -374px;
    text-align: center;
    margin-top: 28px;
  }
  .navigation_next {
    color: whitesmoke;
    font-size: 78px;
    right: 17rem !important;
    z-index: 9999999;
    top: 37rem;
    margin-left: 76vw;
    position: absolute;
    display: flex;
  }
  .navigation_prev {
    position: absolute;
    margin-left: -170rem !important;
    margin-top: -21rem !important;
  }
  .ir_Text {
    position: relative;
    display: flex;
    top: 43%;
    text-align: center;
    margin-left: 35.5%;
    font-weight: 500;
    font-size: 28px;
    transform: scale(1.2);
  }
  .antext {
    margin-left: 33%;
    position: relative;
    display: flex;
    top: 43%;
    text-align: center;
    font-weight: 500;
    font-size: 33px;
  }
  .slides_abt {
    height: 100%;
    width: 100%;
    transform: scale(0.9);
    transform: scale();
    top: -5rem;
  }
  #pos_left {
    left: -7rem;
  }
}

@media screen and (max-width: 699px) {
  #application_rfid {
    display: none;
  }
  .sa_mob_app {
    min-width: 100%;
  }
  .cards1_sec_r {
    margin-left: 12rem;
    margin-top: 0rem;
  }
  .cards3_sec_r3 {
    margin-left: 12rem;
    margin-top: 0rem;
  }
  .cards2_sec_r2 {
    margin-left: 12rem;
    margin-top: 0rem;
  }

  #application_slider_mob {
    top: -9rem;
    min-width: 119%;
  }
  .mob_app_slider {
    width: 392px;
  }
  .slides_abt {
    transform: scale(0.6);
    margin-left: -6rem;
  }
  .text3 {
    position: absolute;
    margin-left: -137px;
    text-align: center;
    margin-top: 28px;
  }
}

@media screen and (max-width: 374px) {
  .text3 {
    position: absolute;
    margin-left: -73px;
    text-align: center;
    margin-top: 28px;
  }
  .mob_app_slider {
    width: 380px !important;
  }
}
@media (min-width: 400px) and (max-width: 500px) {
  .line-1 {
    transform: scale(0.6) !important;
    left: -93%;
    top: 0 !important;
    position: relative;
    width: 34em;
    margin: 0 auto;
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
  }
  .rfidText {
    position: relative;
    display: flex;
    text-align: center;
    margin-left: 26.5%;
    font-weight: 500;
    font-size: 28px;
    transform: scale(0.9);
    top: -29px;
    left: -86px;
    width: 38rem;
  }
  .ircolsector {
    transform: scale(0.6);
  }
  .ir_Text {
    position: relative;
    display: flex;
    text-align: center;
    margin-left: 26.5%;
    font-weight: 500;
    font-size: 28px;
    transform: scale(1.2);
    top: -29px;
    left: -56px;
    width: 35rem;
  }
  .ircardcol {
    transform: scale(0.6);
    left: -280px;
  }
  .irhead {
    transform: scale(0.5) !important;
    left: -147px;
    top: 25px;
    position: relative;

    width: 34em;
    margin: 0 auto;
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
  }
  .ircardcol2 {
    transform: scale(0.6);
    top: -579px;
    left: 178px;
  }

  .fr_text {
    transform: scale(0.46) !important;
  }
  .antext {
    position: relative;
    display: flex;
    text-align: center;
    margin-left: 26.5%;
    font-weight: 500;
    font-size: 28px;
    transform: scale(0.9);
    top: -29px;
    left: -69px;
    width: 34rem;
  }
  .slides_abt {
    transform: scale(0.6);
    margin-left: -6rem;
  }

  .text1 {
    position: absolute;
    text-align: center;
    margin-top: 28px;
    margin-left: 216px;
    width: 281px;
    transform: scale(1.5);
  }
  .mob_adj {
    transform: scale(0.7);
  }
}

@media (min-width: 374px) and (max-width: 400px) {
  .line-1 {
    transform: scale(0.6) !important;
    left: -113%;
    top: 0 !important;
    position: relative;
    width: 34em;
    margin: 0 auto;
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
  }
  .rfidText {
    position: relative;
    display: flex;
    text-align: center;
    margin-left: 26.5%;
    font-weight: 500;
    font-size: 28px;
    transform: scale(0.9);
    top: -29px;
    left: -54px;
  }

  .ircolsector {
    transform: scale(0.5);
  }

  .ir_Text {
    position: relative;
    display: flex;
    text-align: center;
    margin-left: 26.5%;
    font-weight: 500;
    font-size: 28px;
    transform: scale(1.2);
    top: -29px;
    left: -54px;
  }
  .ircardcol {
    transform: scale(0.5);
    left: -280px;
  }
  .irhead {
    transform: scale(0.4) !important;
    left: -172px;
    top: 25px;
    position: relative;
    width: 34em;
    margin: 0 auto;
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
  }
  .ircardcol2 {
    transform: scale(0.5);
    top: -579px;
    left: 144px;
  }

  .fr_text {
    transform: scale(0.4) !important;
  }
  .antext {
    position: relative;
    display: flex;
    text-align: center;
    margin-left: 26.5%;
    font-weight: 500;
    font-size: 28px;
    transform: scale(0.9);
    top: -29px;
    left: -54px;
  }
  .slides_abt {
    transform: scale(0.6);
    margin-left: -6rem;
  }

  .text1 {
    position: absolute;
    text-align: center;
    margin-top: 28px;
    margin-left: 216px;
    width: 281px;
    transform: scale(1.5);
  }
  .mob_adj {
    transform: scale(0.7);
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .line-1 {
    transform: scale(0.5) !important;

    left: -148%;

    top: 0 !important;
    position: relative;
    width: 34em;
    margin: 0 auto;
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
  }
  .ir_head_txt {
    font-size: 141px !important;
    left: -40rem;
  }
  .rfidText {
    position: relative;
    display: flex;
    text-align: center;
    margin-left: 26.5%;
    font-weight: 500;
    font-size: 28px;
    transform: scale(0.6);
    top: -63px;
    left: -59px;
  }
  .ircolsector {
    transform: scale(0.4);
    min-width: 56rem;
  }
  .ir_Text {
    position: relative;
    display: flex;
    text-align: center;
    margin-left: 26.5%;
    font-weight: 500;
    font-size: 28px;
    transform: scale(1.2);
    top: -29px;
    left: -54px;
  }
  .ircardcol {
    transform: scale(0.45);
    left: -271px;
  }
  .irhead {
    transform: scale(0.4) !important;
    left: -200px;
    top: 25px;
    position: relative;
    width: 34em;
    margin: 0 auto;
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
  }
  .ircardcol2 {
    transform: scale(0.45);
    top: -579px;
    left: 117px;
  }

  .fr_text {
    transform: scale(0.3) !important;
  }
  .frtext {
    position: relative;
    display: flex;
    text-align: center;
    margin-left: 26.5%;
    font-weight: 500;
    font-size: 28px;
    transform: scale(0.6);
    top: -84px;
    left: -143px;
    width: 41rem;
  }
  .antext {
    position: relative;
    display: flex;
    text-align: center;
    margin-left: 26.5%;
    font-weight: 500;
    font-size: 28px;
    transform: scale(0.6);
    top: -31px;
    left: -116px;
    width: 35rem;
  }
  .slides_abt {
    transform: scale(0.6);
    margin-left: -6rem;
  }

  .text1 {
    position: absolute;
    text-align: center;
    margin-top: 28px;
    margin-left: 216px;
    width: 281px;
    transform: scale(1.5);
  }
  .mob_adj {
    transform: scale(0.7);
  }
}
