.subtotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 100px;
    padding: 20px;
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    border-radius: 3px;
  }
  
  .subtotal_gift {
    display: flex;
    align-items: center;
  }
  
  .subtotal_gift > input {
    margin-right: 5px;
  }
  
  .subtotal > button {
    background-color: #1bb8cc;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: #1bb8cc;
    color: #111;
  }