.dataloggerPr {
  background: black !important;
}
.dlbck {
  background: black !important;
}
.Product_heading_1_dl {
  margin-left: 78rem !important;
  margin-top: -30rem;
  font-size: 3rem;
  color: white;
  margin-bottom: 19rem;
}
.bd {
  font-weight: 700;
}
#qote_row_dl {
  margin-top: -15rem;
}

@media (min-width: 1800px) and (max-width: 2200px) and (min-height: 800px) and (max-height: 1100px) {
  .Product_heading_1_dl {
    margin-left: 100rem !important;
    margin-top: -47rem;
    font-size: 6rem;
    color: white;
    margin-bottom: 19rem;
  }
  .qote_row_dl {
    margin-top: -11rem;
    margin-bottom: 21rem;
  }
  .irpgrid_2_dl {
    margin-top: 6rem;
    width: 140rem;
    background: black;
    transform: scale(1.2);
  }
}

.bd_600 {
  font-weight: 600;
}
.col_p_text {
  font-size: 5rem;
  min-width: 78rem;
}
.col_p_text2 {
  font-size: 5rem;
  margin-top: 3rem;
}
.qote_btn_irp {
  margin-left: 78rem;
  margin-top: 2rem;
}
.quotebtndn_col {
  margin-left: 78rem;
  font-size: 2rem;
  margin-top: 2rem;
}
.pirrfid {
  background: white;
}
.wd_dl {
  min-width: 57rem !important;
}
.irpgriddl {
  background: black;
}
.feature_irp_dl {
  color: white;
}
.pd_rowir_des_dl {
  color: white;
}
.irpgrid_2_dl {
  margin-top: 6rem;
  width: 140rem;
  background: black;
}
.irpgrid_3_dl {
  margin-top: 6rem;
  width: 140rem;
  background: black;
  color: white;
}
.rowirp_player_dl1 {
  height: 100vh;
  background-color: white;
  text-align: center;
  align-items: center;
  width: 102vw;
  margin-left: -1rem;
  margin-top: 11rem;
}
.vdo2bck_dl {
  background: #edeced !important;
}
.pd_rowir {
  background: #f2f5f8;

  margin-top: 4rem;
}
.pd_rowir_feature {
  background: #f2f5f8;
  margin-top: 4rem;
  width: 50rem;
}
.pd_colir {
  font-size: 3rem;
}
.bd_500 {
  font-weight: 500;
}
.pd_irtext {
  font-size: 2.5rem;
}
.pd_txt_colir {
  text-align: left;
  margin-left: 3rem;
  margin-top: 2rem;
}
.features_irp_row {
  display: flex;
  justify-content: space-between;
}
.colfeature_irp {
  font-size: 2.4rem;
  display: flex;
  justify-content: space-between;
  margin-left: 3rem;
  margin-top: 2rem;
  min-width: 51rem;
}
.feature_count {
  min-width: 46rem;
}
.dl_img1_product {
  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1619648086/ambiTag_1_t1czku.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;

  margin-top: 4rem;
}
.grpbck {
  background-color: #dfdedf !important;
}
.imgcol_dl_p2 {
  background: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1619648093/1_ipp8tt.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}
.bigqote_btn_irp {
  margin-left: 36rem;
  margin-top: 6rem;
  width: 100% !important;
}
.irpfooter {
  margin-left: -1rem;
  width: 101% !important;
}
.rowirp_player {
  height: 100vh;
  background-color: white;
  text-align: center;
  align-items: center;
  width: 102vw;
  margin-left: -1rem;
}
@media (min-width: 2500px) and (max-width: 2700px) {
  .slide-container {
    width: 45vw;
    height: 51vh;
    top: -1rem;
    left: -0.5rem;
    background-color: white;
    position: relative;
  }
  .pd_irtext {
    font-size: 3.5rem;
  }
  .qote_btn_irp {
    margin-left: 78rem;
    margin-top: 13rem;
  }
  .pd_rowir {
    background: #f2f5f8;
    margin-top: 4rem;
    transform: scale(1.2);
    margin-left: 20rem;
    width: 202rem;
  }

  .ga_2560 {
    margin-left: 5rem;
  }
  .pd_rowir_feature {
    background: #f2f5f8;
    margin-top: 6rem;
    width: 50rem;
    margin-left: 4.3rem;
    transform: scale(1.2);
  }
  .feature_irp {
    transform: scale(1.2);
    margin-left: 8rem;
  }
  .col_p_head {
    margin-top: -24rem;
    transform: scale(1.5);
    margin-left: 76rem;
  }
  .col_p_text {
    transform: scale(1.5);
    margin-left: 76rem;
    margin-top: -19rem;
  }
  .col_p_text2 {
    font-size: 5rem;
    margin-top: -15rem !important;
    margin-left: 61rem;
    transform: scale(1.2);
  }
  .col_p_text3 {
    margin-left: 77rem;
    margin-top: -6rem;
    transform: scale(1.5);
  }
  .qote_row_irp {
    transform: scale(1.2);
  }
  .irpgrid {
    background: white;

    height: 67rem;
  }
  .qote_row_irp {
    margin-left: 61rem;
    transform: scale(1.5);
  }
  .react-slideshow-container {
    display: flex;
    align-items: center;
    margin-top: -4.2rem;
  }
  .colfeature_irp {
    font-size: 3rem;
    display: flex;
    justify-content: space-between;
    margin-left: 3rem;
    margin-top: 2rem;
    min-width: 51rem;
  }
  .irpgrid_3 {
    margin-top: 17rem;
    width: 140rem;
    background: white;

    transform: scale(1.5);
  }
  .bigqote_row_irp {
    margin-top: 8rem;
    transform: scale(1.5);
    margin-left: 47rem;
  }
  .contact_footer_dlp > .Footer > .footergrid > .menu_foot > .menus_quicklink2 {
    top: -9rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    left: 23rem;
    transform: scale(1.5);
  }
}
@media (min-width: 424px) and (max-width: 699px) {
  .irpgrid_2 {
    margin-top: -12rem;
    width: 140rem;
    background: white;
  }
  .contact_footer_dlp > .Footer > .footergrid > .menu_foot > .menus_quicklink2 {
    top: -12rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    left: -5rem;
  }
  .features_irp_row {
    transform: scale(0.8);
    margin-left: -44rem;
    margin-top: -6rem;
  }
  .pd_txt_colir {
    text-align: center;
    margin-left: 1rem;

    margin-top: 2rem;
    max-width: 91rem;
    max-width: 39rem !important;
  }
  #dl_mob_pnp {
    margin-left: 2rem;
  }
  #colfeature_dl {
    font-size: 2.4rem;
    display: flex;
    justify-content: space-between;
    margin-left: 3rem;
    margin-top: 2rem;
    min-width: 45rem !important;
    line-height: 4rem;
  }

  .col_irp_features {
    margin-left: 23rem;
    margin-top: 6rem;
  }
  .irpgrid_3 {
    margin-top: 6rem;
    width: 140rem;
    background: white;
    transform: scale(0.7);
    margin-left: -20rem;
  }

  .bigqote_row_irp {
    transform: scale(0.8);
    margin-left: -30rem;
  }
  .imgcol_ipr_p2 {
    background: url(https://res.cloudinary.com/dpysmqax5/image/upload/v1618752240/irp1_featurgallery3.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
    margin-top: -4rem;
  }
  .irpfooter {
    margin-left: -1rem;
    width: 105% !important;
    height: 43rem;
  }
}
@media (min-width: 374px) and (max-width: 424px) {
  .irpgrid_2 {
    margin-top: -12rem;
    width: 140rem;
    background: white;
  }
  .contact_footer_dlp > .Footer > .footergrid > .menu_foot > .menus_quicklink2 {
    top: -12rem;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    left: -5rem;
  }
  .pd_txt_colir {
    text-align: center;
    margin-left: 1rem;

    margin-top: 2rem;

    max-width: 34rem !important;
  }
  .features_irp_row {
    transform: scale(0.8);
    margin-left: -36rem;
    margin-top: -6rem;
  }
  .col_irp_features {
    margin-left: 23rem;
    margin-top: 6rem;
  }
  .irpgrid_3 {
    margin-top: 6rem;
    width: 140rem;
    background: white;
    transform: scale(0.7);
    margin-left: -20rem;
  }
  .drp_icn_cirp {
    margin-left: 45rem !important;
    margin-top: -1rem !important;
  }
  .bigqote_row_irp {
    transform: scale(0.8);
    margin-left: -30rem;
  }
  .imgcol_fpr_p2 {
    background: url(https://res.cloudinary.com/dpysmqax5/image/upload/v1618753383/2_port_Fixed_Reader_rzm6bf.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 60vh;
  }
  .irpfooter {
    margin-left: -1rem;
    width: 105% !important;
    height: 43rem;
  }
  .imgcol_fpr_p2 {
    height: 60vh;
  }
  .fpr_img1_product {
    background: url(https://res.cloudinary.com/dpysmqax5/image/upload/v1618753385/2_Port_Fixed_Reader_3_pfsjs7.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 58vh;
    margin-top: 4rem;
  }
  .pc_irpc_text {
    width: 88%;
  }
}
@media (min-width: 1800px) and (max-width: 2200px) and (min-height: 800px) and (max-height: 1100px) {
  .Product_heading_1 {
    margin-left: 100rem !important;
    margin-top: -39rem;
    font-size: 3rem;
  }
  .qote_btn_irp {
    margin-left: 103rem;
    margin-top: 2rem;
    transform: scale(1.5);
  }
  .react-slideshow-container .default-nav:first-of-type {
    margin-top: 41rem;
    position: absolute;
    margin-left: 33rem;
    transform: scale(1.5);
  }
  .react-slideshow-container .default-nav:last-of-type {
    position: absolute;
    margin-left: 45rem !important;
    margin-top: 41rem;
    transform: scale(1.5);
  }
}

@media (min-width: 1800px) and (max-width: 2200px) and (min-height: 800px) and (max-height: 1100px) {
  .Product_heading_1_dl {
    margin-left: 100rem !important;
    margin-top: -47rem;
    font-size: 6rem;
    color: white;
    margin-bottom: 19rem;
  }
  #qote_row_dl {
    margin-top: -11rem;
    margin-bottom: 21rem;
  }
}
