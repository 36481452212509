.checkout {
    display: flex;
    padding: 20px;
    background-color: black;
    height: max-content;
  }
  
  .checkout_ad {
    width: 1000px;
    margin-bottom: 10px;
    height: 100px;
  }
  
  .checkout__title {
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
  }