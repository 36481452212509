.checkoutProduct {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .checkoutProduct__info {
    padding-left: 20px;
  }
  
  .checkoutProduct__info > button {
    background: #1bb8cc;
    border: 1px solid;
    margin-top: 10px;
    border-color: #1bb8cc;
    color: #111;
  }
  
  .checkoutProduct__image {
    width: 180px;
    height: 180px;
    padding: 30px;
    object-fit: contain;
  }
  
  .checkoutProduct__rating {
    display: flex;
  }
  
  .checkoutProduct__title {
    font-size: 17px;
    font-weight: 800;
  }