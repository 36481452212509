.loggerimg {
  background-image: url("https://res.cloudinary.com/dpysmqax5/image/upload/v1616783198/ABI_TAG_lrhsxs.jpg") !important;
}

.loggerimg_sector {
  background: linear-gradient(
      rgba(253, 249, 249, 0.5),
      rgba(240, 236, 236, 0.8)
    ),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1616783198/ABI_TAG_lrhsxs.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.loggerimg2 {
  background: linear-gradient(rgba(129, 118, 118, 0.5), rgba(48, 45, 45, 0.8)),
    url("https://res.cloudinary.com/dpysmqax5/image/upload/v1616783198/ABI_TAG_lrhsxs.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  background-attachment: fixed !important;
}
.loggerText {
  position: relative;
  display: flex;
  margin-top: -9% !important;
  text-align: center;
  margin-left: 216px;
  font-weight: 500;
  font-size: 28px;
}
.line_dl {
  font-size: 93px !important;
  width: 100% !important;
}
.txt_cen_l {
  text-align: center !important;
}
.btn_dl_txt {
  margin-top: -285px;
}
.footer_dl_row > .Footer {
  top: -191px !important;
  left: 0 !important;
}

@media (min-width: 2500px) {
  .loggerText {
    position: relative;
    display: flex;
    margin-top: -4% !important;
    text-align: center;
    margin-left: 58rem;
    font-weight: 500;
    font-size: 35px;
  }
  .btn_dl_txt {
    transform: scale(1.5);
  }
  #footer_dl_sec > .Footer {
    top: -253px !important;
    left: 0 !important;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .logger_img {
    transform: scale(0.7);
    margin-top: -3%;
    margin-left: -509px;
  }
  .logger_col {
    padding-left: 47%;
    margin-top: -61%;
    z-index: 999;
    width: 100% !important;
    transform: scale(0.7);
  }
  .ts_16_d {
    margin-top: 210px;
    position: relative;
    top: -737px;
    bottom: 0;
    left: -4%;
    z-index: 999;
    transform: scale(0.7);
  }
  .btn_r_dl {
    margin-top: -684px;
    margin-left: 508px;
    transform: scale(0.7);
  }
  #dl_sut_sec {
    transform: scale(0.7);
    width: 106% !important;
    top: -11rem !important;
  }
}

@media (min-width: 425px) and (max-width: 699px) {
  #dl_head_sec {
    transform: scale(0.5) !important;
  }
  #dl_sut_sec {
    left: -33rem;
    transform: scale(0.3) !important;
    width: 256% !important;

    top: -6rem !important;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  #dl_head_sec {
    transform: scale(0.4) !important;
  }
  #dl_sut_sec {
    left: -35rem;
    transform: scale(0.3) !important;
    width: 293% !important;
    top: -6rem !important;
  }
}

@media (min-width: 300px) and (max-width: 375px) {
  #dl_head_sec {
    transform: scale(0.3) !important;
    left: -44rem;
  }
  #dl_sut_sec {
    left: -41.5rem;
    transform: scale(0.25) !important;
    width: 361% !important;
    top: -6rem !important;
  }
  #dl_textdes_sec {
    transform: scale(0.7);
    left: -1.5rem;
    top: -4rem;
  }
}
