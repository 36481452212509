.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 20px;
  padding: 20px;
  width: 50%;
  max-height: 400px;
  min-width: 50px;
  max-width: 500px;
  background-color: white;
  position: relative;
  z-index: 1;
  top: 6rem;
  background: black;
  border: 1px solid whitesmoke;
}
.product:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.5s;
  pointer-events: none;
}

.product:hover:before {
  left: -5%;
  transform: skewX(-5deg);
}
.product_shop {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
  color: white;
}
.home_row1 {
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-left: 175px;
  margin-right: 5px;
  margin-bottom: 50px;
  background-color: white;
}
.home_row {
  display: flex;
  z-index: 1;
  margin-left: -4rem;
  margin-right: 5px;
  width: 104%;
  height: 100vh;
  justify-content: space-around;
  background: black;
}
img :hover{
  
    transform-origin: 0 0;
    transition: transform .25s, visibility .25s ease-in;
  }
  
  /* The Transformation */
 .image:hover {
    transform: scale(2);
  }

.product:hover {
  transform: scale(1.08);
  transform-style: preserve-3d;
  product-shadow: 2px lightgrey;
}

.product__rating {
  display: flex;
}

.product > img {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}

.product > button {
  background: #1bb8cc;
  border: 1px solid;
  margin-top: 10px;
  border-color: #1bb8cc #9c7e31 #846a29;
  color: #111;
}

.product__price {
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  padding-left: 25%;
  margin-bottom: 5px;
}

.product__info {
  max-height: 100px;
  margin-bottom: 15px;
  padding-left: 5%;
}
.product__rating {
  left: -15px;
}
