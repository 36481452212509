.drp_icn_cirp2 {
  margin-left: 11rem;
  margin-top: -3.5rem;
  transform: scale(0.8);
}
.col_pctxt2 {
  font-size: 1.6rem;
  font-weight: 300;
  margin-left: 0rem;
  padding-left: 0rem;
}
.app__collapse2 {
  margin-left: -4rem;
}
.irpc_rows2 {
  margin-top: -2rem;
}
.app_collap {
  margin: 0 auto;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  padding: 3rem 1rem 1rem;

  &__text {
    width: 100%;
  }

  &__collapse {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);

    &--gradient {
      .app__content {
        border-color: transparent;
      }

      &:after {
        pointer-events: none;
        transition: opacity 300ms;
        will-change: opacity;
        opacity: 1;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent 50%, white);
      }
    }

    &--active {
      &:after {
        opacity: 0;
      }
    }
  }

  &__content {
    border: 1px solid transparent;
    padding: 1rem 0;
    margin-bottom: 3rem;
    position: relative;
    transition: 300ms;
  }

  &__toggle {
    width: 100%;
    margin: 1rem 0;
    border: 1px solid transparent;
    text-transform: uppercase;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0 2px 4px #888;
    background-color: #fff;
    transition: background-color 300ms;
    &--active {
      background-color: #fafafa;
    }
  }

  &__toggle-text {
    font-size: inherit;
  }

  &__button {
    display: block;
    width: 20rem;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }

  &__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }
}

.icon {
  width: 1em;
  height: 1em;
  transition: transform 300ms cubic-bezier(0, 1, 0, 1);
  will-change: transform;
  &--expanded {
    transform: rotateZ(-179.99deg);
  }
}

.rotate90 {
  transform: rotateZ(90deg);
}
.btn_collap {
  background: none;
  min-height: 2rem;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.btn_row_c {
  font-size: 3rem;
  font-weight: 500;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.btn_row_c2 {
  font-size: 1.7rem;
  font-weight: 500;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.col_pctxt {
  font-size: 2rem;
  font-weight: 300;
  margin-left: 0rem;
  padding-left: 0rem;
}
.drp_icn_cirp {
  margin-left: 131rem;
  margin-top: -1rem;
}
.inner_text_irp {
  left: 5rem;
  padding-left: 0rem;
}
@media (min-width: 320px) and (max-width: 374px) {
  .btn_row_c {
    font-size: 3rem;
    font-weight: 500;
    width: 111vw !important;
    display: flex;
    flex-direction: column;
  }
  .drp_icn_cirp {
    margin-left: 35rem !important;
    margin-top: -1rem !important;
  }
}
@media (min-width: 1800px) and (max-width: 2200px) and (min-height: 800px) and (max-height: 1100px) {
  .btn_row_c2 {
    font-size: 1.4rem;
    font-weight: 500;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
}
